<template>
  <div class="wrapper">

    <Header />

    <div class="admin" v-if="!loading">

      <div class="admin-header">
        <h1 class="heading-r">Companies</h1>
        <button class="button button-primary" v-on:click="showCompanyCreateModal"><img src="@/assets/icon-plus.svg">Company</button>
      </div>

      <div class="admin-grid" v-if="companies.length">
        <div class="admin-grid-item" v-for="company in sortedCompanies" :key="company._id">
          <router-link :to="{ name: 'Company', params: { company: company._id }}">
            <h3 class="heading-xxs">{{ company.name }}</h3>
            <p>{{ company.count }} project<template v-if="company.count != 1">s</template></p>
          </router-link>
          <span class="admin-grid-item-ellipsis" v-on:click="showCompanyDeleteModal(company.name, company._id)"></span>
        </div>
      </div>

      <div class="admin-empty" v-else>
        <img src="@/assets/desert.svg">
        <p>It's pretty empty in here!</p>
      </div>

    </div>

    <div class="loading" v-else>
      <img src="@/assets/loading.gif">
    </div>

    <div class="modal" v-if="createModal">
      <div class="modal-box">
        <h2 class="heading-xs">Create a company</h2>
        <form v-on:submit.prevent="createNewCompany">
          <input type="text" placeholder="Company name" autocomplete="off" required v-model="newCompany.name">
          <button class="button button-primary" type="submit">Create company</button>
        </form>
        <span class="modal-box-close" v-on:click="hideCompanyCreateModal"></span>
      </div>
    </div>

    <div class="modal" v-if="deleteModal">
      <div class="modal-box">
        <h2 class="heading-xs">Delete this company</h2>
        <p>Are you sure you want to permanently delete <strong>{{ companyDeletion.name }}</strong>?</p>
        <button class="button button-danger" v-on:click="deleteCompany(companyDeletion.id)">Delete {{ companyDeletion.name }}</button>
        <span class="modal-box-close" v-on:click="hideCompanyDeleteModal"></span>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import Header from '@/components/Header'

export default {
  name: 'Admin',
  components: {
    Header
  },
  data () {
    return {
      loading: true,
      newCompany: {
        name: ''
      },
      companyDeletion: {
        name: '',
        id: ''
      },
      companies: [],
      createModal: false,
      deleteModal: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    sortedCompanies: function () {
      return this.companies.slice(0).sort(function (a, b) {
        return new Date(b.created) - new Date(a.created)
      })
    }
  },
  methods: {
    logOutUser: function () {
      const self = this
      this.$store.dispatch('logOutUser').then(function () {
        self.$router.push('/login')
      })
    },
    getAllCompanies: function () {
      const self = this
      self.loading = true
      axios.get('/api/companies/all').then(function (response) {
        self.companies = response.data
        self.loading = false
      })
    },
    showCompanyCreateModal: function () {
      this.createModal = true
    },
    hideCompanyCreateModal: function () {
      this.createModal = false
    },
    showCompanyDeleteModal: function (name, id) {
      this.companyDeletion.name = name
      this.companyDeletion.id = id
      this.deleteModal = true
    },
    hideCompanyDeleteModal: function () {
      this.companyDeletion.name = ''
      this.companyDeletion.id = ''
      this.deleteModal = false
    },
    createNewCompany: function () {
      const self = this
      axios.post('/api/companies/create', {
        name: this.newCompany.name
      }).then(function (response) {
        self.companies.push(response.data)
        self.createModal = false
        self.newCompany.name = ''
      })
    },
    deleteCompany: function (id) {
      const self = this
      axios.post('/api/companies/' + id + '/delete').then(function (response) {
        self.getAllCompanies()
        self.companyDeletion.name = ''
        self.companyDeletion.id = ''
        self.deleteModal = false
      })
    }
  },
  created () {
    this.getAllCompanies()
  }
}
</script>
